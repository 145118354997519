import React, { Component } from 'react';

import I18n from 'common/i18n';

interface Props {
  redirectUrl: string;
}

class AppRedirect extends Component<Props> {
  componentDidMount() {
    const { redirectUrl } = this.props;

    // redirect to the url after giving the user 5 seconds to read the message
    setTimeout(function () {
      window.location.replace('https://' + redirectUrl);
    }, 5000);
  }

  render() {
    const { redirectUrl } = this.props;
    const fullUrl = 'https://' + redirectUrl;

    return (
      <div id="app-redirect-wrapper">
        <div id="app-redirect">
          <div id="app-redirect-image">
            <img
              src="images/verification-success.svg"
              alt={I18n.t('account.common.verify_email.redirect_image_alt')}
            />
          </div>
          <div id="app-redirect-text">
            <p>{I18n.t('account.common.verify_email.redirect', { redirectUrl: redirectUrl })}</p>
            <a href={fullUrl}>{fullUrl}</a>
          </div>
        </div>
      </div>
    );
  }
}

export default AppRedirect;
