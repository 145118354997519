import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import isEmpty from 'lodash/isEmpty';

import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import Form from 'common/components/Forms/Form';
import ReduxValidatedInput from 'common/components/Forms/redux/ReduxValidatedInput';
import { validateForm } from 'common/components/Forms/redux/FormReduxActions';
import ReduxValidatedFormReCaptcha from 'common/components/Forms/redux/ReduxValidatedFormReCaptcha';
import I18n from 'common/i18n';
import Button, { SIZES, VARIANTS } from 'common/components/Button';
import PasswordHintModal from 'common/components/PasswordHintModal';

import { Options } from '../../types';

import './sign-up-form.scss';

const classNameScope = 'frontend--authentication--components--sign-up-form';

interface OwnProps {
  options: Options;
}
interface StateProps {
  formSubmitted: boolean;
  enteredEmail: string;
  enteredScreenName: string;
  urlAuthToken: string;
  redirectUrl: string;
  initiatedBy: string;
  shouldGoThroughSso: boolean;
}
interface DispatchProps {
  onFormSubmit: (callback: () => void, event: React.FormEvent<HTMLFormElement>) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

interface State {
  renderingPasswordHintModal: boolean;
  shouldGoThroughSso: boolean;
}

class SignUpForm extends Component<Props, State> {
  state = {
    renderingPasswordHintModal: false,
    shouldGoThroughSso: false
  };

  getFormActionUrl = () => {
    const { options } = this.props;
    const { signupPath } = options;

    return signupPath;
  };

  // this function is called only if the form passes validation in the reducer;
  // note that directly doing submit() on the DOM node skips firing the onSubmit event
  submitValidatedForm = (event: React.FormEvent<HTMLFormElement>) => {
    const { onFormSubmit } = this.props;

    event.preventDefault();

    // this calls out to the reducer, and the function passed in is called if the form is 100% valid
    // this will submit the DOM node, which will result in a POST to frontend to initiate the signup process
    onFormSubmit(() => event.currentTarget.submit(), event);
  };

  openPasswordHintModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    this.setState({ renderingPasswordHintModal: true });
  };

  closePasswordHintModal = () => {
    this.setState({ renderingPasswordHintModal: false });
  };

  renderPasswordHintModal = () => {
    const {
      options: { passwordMinLength, passwordMaxLength }
    } = this.props;
    if (this.state.renderingPasswordHintModal) {
      return (
        <PasswordHintModal
          onDismiss={this.closePasswordHintModal}
          passwordRequirements={{
            minLength: passwordMinLength,
            maxLength: passwordMaxLength
          }}
        />
      );
    }

    return null;
  };

  /**
   * Renders a hidden input with an auth token passed in by URL params,
   * for signing up with "bulk created" (aka "future") accounts
   */
  renderUrlAuthToken() {
    const { urlAuthToken } = this.props;

    if (!isEmpty(urlAuthToken)) {
      return <input name="signup[authToken]" type="hidden" id="signup_authToken" value={urlAuthToken} />;
    }

    return null;
  }

  /**
   * Renders a hidden input with the redirect_url if passed in by URL params
   */
  renderRedirectUrl() {
    const { redirectUrl } = this.props;

    if (!isEmpty(redirectUrl)) {
      return <input name="signup[redirectUrl]" type="hidden" id="signup_redirectUrl" value={redirectUrl} />;
    }

    return null;
  }

  /**
   * Renders a hidden input with initiated_by if passed in by URL params
   */
  renderInitiatedBy() {
    const { initiatedBy } = this.props;

    if (!isEmpty(initiatedBy)) {
      return <input name="signup[initiatedBy]" type="hidden" id="signup_initiatedBy" value={initiatedBy} />;
    }

    return null;
  }

  render() {
    const { options, enteredEmail, enteredScreenName, formSubmitted, shouldGoThroughSso } = this.props;

    const { loginPath } = options;

    // sometimes we can come back to this page but with pre-filled bits of the form
    // (i.e. if the user clicks a link from an email)
    // so we have to figure out which input to focus on when the page loads
    const focusOnEmail = isEmpty(enteredEmail);
    const focusOnScreenName = !focusOnEmail && isEmpty(enteredScreenName);
    const focusOnPassword = !focusOnEmail && !focusOnScreenName;

    return (
      <Form action={this.getFormActionUrl()} noValidate onSubmit={this.submitValidatedForm}>
        {this.renderPasswordHintModal()}
        {this.renderUrlAuthToken()}
        {this.renderRedirectUrl()}
        {this.renderInitiatedBy()}

        {/* ..... don't ask, yes it's needed */}
        <input type="hidden" name="signup[accept_terms]" id="signup_accept_terms" value="true" />

        {/* Email */}
        <ReduxValidatedInput autoFocus={focusOnEmail} name="signup[email]" id="signup-email" tabIndex={0} />

        {/* If the email the user entered should go through SSO, tell them so */}
        {shouldGoThroughSso && (
          <div className="alert warning">
            {I18n.t('screens.sign_up.use_sso')}{' '}
            <Link className={`${classNameScope}--signin-link`} to={loginPath}>
              {I18n.t('screens.sign_up.use_sso_go_to_login')}
            </Link>
          </div>
        )}

        {/* Screen Name */}
        <ReduxValidatedInput autoFocus={focusOnScreenName} name="signup[screenName]" id="signup-screenName" />

        {/* Password */}
        <ReduxValidatedInput autoFocus={focusOnPassword} name="signup[password]" id="signup-password">
          <button
            type="button"
            className={`${classNameScope}--password-hint-button`}
            onClick={this.openPasswordHintModal}
          >
            {I18n.t('account.common.form.password_restrictions')}
            <SocrataIcon name={IconName.Info} />
          </button>
        </ReduxValidatedInput>

        {/* Password Confirm*/}
        <ReduxValidatedInput name="signup[passwordConfirm]" id="signup-passwordConfirm" />

        <ReduxValidatedFormReCaptcha />

        <div
          className={`${classNameScope}--terms`}
          dangerouslySetInnerHTML={{
            __html: I18n.t('screens.sign_up.tyler_terms_html')
          }}
        />
        <Button
          disabled={shouldGoThroughSso}
          busy={formSubmitted}
          size={SIZES.LARGE}
          variant={VARIANTS.PRIMARY}
          dark
          className={`${classNameScope}--sign-up-button`}
          type="submit"
        >
          {I18n.t('screens.sign_up.form.create_account_button')}
        </Button>
      </Form>
    );
  }
}

interface StateMockUp {
  formSubmitted: boolean;
  inputs: {
    [key: string]: { value: string };
  };
  urlAuthToken: string;
  redirectUrl: string;
  initiatedBy: string;
  shouldGoThroughSso: boolean;
}

const mapStateToProps = (state: StateMockUp): StateProps => ({
  formSubmitted: state.formSubmitted,
  enteredEmail: state.inputs['signup[email]'].value,
  enteredScreenName: state.inputs['signup[screenName]'].value,
  urlAuthToken: state.urlAuthToken,
  redirectUrl: state.redirectUrl,
  initiatedBy: state.initiatedBy,
  shouldGoThroughSso: state.shouldGoThroughSso
});

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) =>
  bindActionCreators(
    {
      onFormSubmit: (callback, event) => {
        const {
          options: { passwordMinLength, passwordMaxLength, ssoConnections, platformAdminConnection }
        } = ownProps;
        return validateForm(callback, event, {
          passwordMinLength,
          passwordMaxLength,
          ssoConnections,
          platformAdminConnection
        });
      }
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
