import React, { Component } from 'react';

import Button, { SIZES, VARIANTS } from 'common/components/Button';
import I18n from 'common/i18n';
import Form from 'common/components/Forms/Form';
import Input from 'common/components/Forms/Input';

import Container from '../Container';
import { Options } from '../../types';

import './mfa.scss';

const classNameScope = 'frontend--authentication--components--mfa';

class ValidateMfa extends Component<Partial<Options>> {
  render() {
    const { flashes, validateOtpPath, cancelMfaPath } = this.props;
    return (
      <Container flashes={flashes} header={I18n.t('account.common.mfa.header')}>
        <div className={`${classNameScope}--instructions`}>
          <div className={`${classNameScope}--text`}>
            <p>{I18n.t('account.common.mfa.enter_code_instructions')}</p>
            <p>
              <a href="https://support.socrata.com/hc/en-us/articles/360016843234-Two-Factor-Authentication">
                {I18n.t('account.common.mfa.learn_more')}
              </a>
            </p>
          </div>
        </div>
        <Form action={validateOtpPath} onSubmit={() => {}}>
          <Input
            autoFocus
            label={I18n.t('account.common.mfa.enter_code')}
            name="otp"
            placeholder={I18n.t('account.common.mfa.enter_code')}
            type="number"
          />
          <div className={`${classNameScope}--button-container`}>
            <Form action={cancelMfaPath}>
              <Button size={SIZES.LARGE} type="submit">
                {I18n.t('account.common.mfa.cancel')}
              </Button>
            </Form>
            <Button variant={VARIANTS.PRIMARY} dark size={SIZES.LARGE} type="submit">
              {I18n.t('account.common.mfa.submit')}
            </Button>
          </div>
        </Form>
      </Container>
    );
  }
}

export default ValidateMfa;
