import React, { Component } from 'react';
import get from 'lodash/get';

import I18n from 'common/i18n';
import Button, { VARIANTS } from 'common/components/Button';
import { Modal, ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';

import { ModalConfig } from '../types';

interface Props {
  modalConfig: ModalConfig;
  onCancel: () => void;
  onConfirm: () => void;
}

class LoginModal extends Component<Props> {
  cancelModal: () => void;

  render() {
    const { modalConfig, onCancel, onConfirm } = this.props;

    const acceptButtonText = get(
      modalConfig,
      'acceptButtonText',
      I18n.t('screens.sign_in.modal_default_accept')
    );

    const headerText = get(modalConfig, 'title', I18n.t('screens.sign_in.modal_default_header'));

    // there is validation in rails AND types for this field,
    // so it _should_ always be there
    const text = get(modalConfig, 'text', 'ERROR: Missing text config value for modal!');

    const hideCancelButton = get(modalConfig, 'hideCancelButton', false);
    const cancelButtonText = get(
      modalConfig,
      'cancelButtonText',
      I18n.t('screens.sign_in.modal_default_cancel')
    );

    const modalProps = {
      fullScreen: false,
      onDismiss: () => {} // don't allow clicking outside to hide
    };

    const headerProps = {
      showCloseButton: false,
      title: headerText,
      onDismiss: () => {
        this.cancelModal();
      }
    };

    const renderCancelButton = (hideButton: boolean) => {
      if (hideButton) {
        return null;
      }

      return (
        <Button
          onClick={() => {
            onCancel();
          }}
        >
          {cancelButtonText}
        </Button>
      );
    };

    return (
      <Modal {...modalProps}>
        <ModalHeader {...headerProps} />

        <ModalContent>
          {/* This value is only set by us, so dangerous inner HTML is okay */}
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </ModalContent>

        <ModalFooter>
          <div>
            {renderCancelButton(hideCancelButton)}{' '}
            <Button
              variant={VARIANTS.PRIMARY}
              onClick={() => {
                onConfirm();
              }}
            >
              {acceptButtonText}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default LoginModal;
