import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import I18n from 'common/i18n';

import { Options, SsoConnection } from '../../types';

import './choose-connection.scss';

const classNameScope = 'frontend--authentication--components--choose-connection';

interface Props {
  options: Options;
  onConnectionChosen: (connection: SsoConnection) => void;
  setLoginFormVisibility: (isVisible: boolean) => void;
}

class ChooseConnection extends Component<Props> {
  getConnectionText = (ssoConnection: SsoConnection) => {
    return isEmpty(ssoConnection.buttonText)
      ? I18n.t('screens.sign_in.sign_in_with', { provider: ssoConnection.name })
      : ssoConnection.buttonText;
  };

  renderConnectionImage = (ssoConnection: SsoConnection) => {
    const { image, name } = ssoConnection;
    if (!isEmpty(image)) {
      return (
        <div className={`${classNameScope}--button-image-container`}>
          <img
            className={`${classNameScope}--button-image`}
            src={image}
            alt={!isEmpty(name) ? name : 'SSO Connection'}
          />
        </div>
      );
    }
  };

  renderSocrataIdButton = () => {
    if (!this.props.options.hideSocrataId) {
      const { setLoginFormVisibility } = this.props;
      return (
        <button
          className={`signin-button-socrata-id btn btn-default ${classNameScope}--button`}
          onClick={() => setLoginFormVisibility(true)}
        >
          <div className={`${classNameScope}--button-image-container`}>
            <img
              className={`${classNameScope}--button-image`}
              src={'/stylesheets/images/common/tyler-logo.png'}
              alt={'tyler-logo'}
            />
          </div>
          <span
            className={`${classNameScope}--button-text`}
            dangerouslySetInnerHTML={{
              __html: I18n.t('screens.sign_in.sign_in_with', {
                provider: 'Tyler Data & Insights ID'
              })
            }}
          />
        </button>
      );
    }
  };

  renderConnections = () => {
    const { onConnectionChosen, options } = this.props;

    return options.ssoConnections
      .filter((connection) => connection.displayOnLogin)
      .map((ssoConnection) => {
        const { id, image } = ssoConnection;
        const textStyle = isEmpty(image) ? 'button-text-no-image' : 'button-text';

        return (
          <button
            key={id.replace(' ', '')}
            className={`btn btn-default ${classNameScope}--button`}
            onClick={() => onConnectionChosen(ssoConnection)}
          >
            {this.renderConnectionImage(ssoConnection)}
            <span
              className={`${classNameScope}--${textStyle}`}
              dangerouslySetInnerHTML={{ __html: this.getConnectionText(ssoConnection) }}
            />
          </button>
        );
      });
  };

  render() {
    const { options } = this.props;

    return (
      <div className={`${classNameScope}--container`}>
        <div
          className={`${classNameScope}--message`}
          dangerouslySetInnerHTML={{ __html: options.chooseConnectionMessage }}
        />
        {this.renderSocrataIdButton()}
        {this.renderConnections()}
      </div>
    );
  }
}

export default ChooseConnection;
