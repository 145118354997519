import React from 'react';
import ReactDOM from 'react-dom';

import Authentication from './components/Authentication';
import { Options } from './types';
import get from 'lodash/get';

const options: Options = get(window, 'socrata.authenticationOptions');
const rootNode = document.querySelector('#authentication'); // Rendered in two places, as of this writing

ReactDOM.render(<Authentication config={options} />, rootNode);
