import React, { Component } from 'react';

import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import Input from 'common/components/Forms/Input';
import I18n from 'common/i18n';

import { classNameScope } from './SignInForm';
import { SsoConnection } from 'authentication/types';

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  connection: SsoConnection | null;
}

class PasswordInput extends Component<Props> {
  renderSsoEnabled = (isUsingSingleSignOn: boolean) => {
    return (
      <div
        className={`signin-sso-enabled-container ${classNameScope}--sso-enabled-container`}
        style={{ visibility: !isUsingSingleSignOn ? 'hidden' : 'visible' }}
      >
        <div className={`${classNameScope}--icon-container`}>
          <SocrataIcon name={IconName.Private} />
        </div>
        <div className={`signin-password-sso-enabled-text ${classNameScope}--sso-enabled-text`}>
          {I18n.t('screens.sign_in.sso_enabled')}
        </div>
      </div>
    );
  };

  renderInput = (isUsingSingleSignOn: boolean) => {
    const { onChange } = this.props;

    return (
      <div
        className="signin-password-input-container"
        style={{ visibility: isUsingSingleSignOn ? 'hidden' : 'visible' }}
      >
        <div className={`${classNameScope}--icon-container`}>
          <SocrataIcon name={IconName.Private} />
        </div>
        <Input
          name="user_session[password]"
          aria-label={I18n.t('screens.sign_in.form.password_placeholder')}
          className={`text-input ${classNameScope}--input-password`}
          type="password"
          placeholder={I18n.t('screens.sign_in.form.password_placeholder')}
          onChange={onChange}
        />
      </div>
    );
  };

  render() {
    const isUsingSingleSignOn = this.props.connection != null;
    // Note that both the input and the "SSO enabled" text are rendered
    // but their visibility is toggled by whether or not we have been
    // passed a connection name via props.
    //
    // Originally, this component conditionally rendered one or another
    // but some browser plugins (i.e. lastpass) would detect the form "changing"
    // and "helpfully" fill it back in, making it impossible to clear out the inputs
    return (
      <div>
        {this.renderInput(isUsingSingleSignOn)}
        {this.renderSsoEnabled(isUsingSingleSignOn)}
      </div>
    );
  }
}

export default PasswordInput;
