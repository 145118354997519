import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import isFinite from 'lodash/isFinite';
import get from 'lodash/get';
import { get as getCookie, set as setCookie, erase as eraseCookie } from 'browser-cookies';

import { Options } from '../types';
import LoginModal from './LoginModal';
import SignInContainer from './SignInContainer';
import SignUp from './SignUp';

interface Props {
  signin?: boolean;
  options: Options;
}

interface State {
  displayingModal: boolean;
  displayingSignIn: boolean;
  clearFlashes: boolean;
}

class SignInSignUpSwitcher extends Component<Props, State> {
  static defaultProps = {
    signin: false
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      // whether or not the modal is being displayed
      // this takes precedence over rendering the signin/signup components
      displayingModal: this.shouldDisplayModal(),

      // if true, we're on sign in, else we're on sign up
      displayingSignIn: props.signin!,

      // if this is set to true, then on next render all flashes will be cleared
      clearFlashes: false
    };
  }

  cookieName() {
    return 'socrata-login-modal-accepted';
  }

  shouldDisplayModal = () => {
    const modalConfig = this.props.options.modalConfig;

    // only show if we actually have the config
    if (!isEmpty(modalConfig)) {
      const expirationMinutes = modalConfig!.expirationMinutes;

      // empty or 0 expiration minutes means we show the modal every time
      if (!isFinite(expirationMinutes) || expirationMinutes === 0) {
        return true;
      }

      const cookie = getCookie(this.cookieName());

      if (isEmpty(cookie)) {
        // no cookie means it hasn't been accepted yet
        return true;
      } else {
        if (expirationMinutes! < 0) {
          // negative expiration minutes means the accept never expires as long as the cookie is around
          return false;
        }

        const date = new Date(cookie);
        const differenceMinutes = (Date.now() - date.getTime()) / 60000;

        // cookie has "expired"
        // if the difference is not finite, it means the parsed date is invalid
        if (!isFinite(differenceMinutes) || differenceMinutes > expirationMinutes!) {
          eraseCookie(this.cookieName());
          return true;
        }
      }
    }

    return false;
  };

  toggleViewMode = (clearFlashes = true) => {
    this.setState({ displayingSignIn: !this.state.displayingSignIn, clearFlashes: clearFlashes });
  };

  cancelModal = () => {
    eraseCookie(this.cookieName());

    window.location = get(this.props, 'options.modalConfig.cancelRedirectUrl', '/');
  };

  confirmModal = () => {
    setCookie(this.cookieName(), new Date().toString());

    this.setState({ displayingModal: false });
  };

  render() {
    const { displayingModal, displayingSignIn, clearFlashes } = this.state;
    const { options } = this.props;
    const opts = { ...options, flashes: clearFlashes ? [] : options.flashes };

    if (displayingModal) {
      return (
        <LoginModal
          modalConfig={options.modalConfig!}
          onCancel={this.cancelModal}
          onConfirm={this.confirmModal}
        />
      );
    } else if (displayingSignIn) {
      return <SignInContainer options={opts} />;
    } else {
      return <SignUp options={opts} />;
    }
  }
}

export default SignInSignUpSwitcher;
