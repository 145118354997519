import React, { Component } from 'react';
import { Link } from 'react-router';

import I18n from 'common/i18n';

import { Alert, Options } from '../types';
import SignInForm from './SignInForm/SignInForm';
import { classNameScope } from './SignInContainer';

interface Props {
  options: Options;
  onLoginStart: () => void;
  onLoginError: (level: Alert['level'], message: string) => void;
  setLoginFormVisibility: (show: boolean) => void;
}

class SignIn extends Component<Props> {
  renderSignUpLink = () => {
    const { options } = this.props;
    const { showSignup, restrictCommunityAccess, signupPath } = options;

    if (showSignup === false || restrictCommunityAccess) {
      return null;
    }

    return (
      <div className={`${classNameScope}--signup-container`}>
        {I18n.t('screens.sign_in.dont_have_account')}{' '}
        <Link className={`${classNameScope}--signup-link`} to={signupPath}>
          {I18n.t('screens.sign_in.sign_up')}
        </Link>
      </div>
    );
  };

  render() {
    const { options, onLoginError, onLoginStart } = this.props;

    return (
      <div className={`${classNameScope}--inner-container`}>
        <div className={`${classNameScope}--form-container`}>
          <SignInForm options={options} onLoginStart={onLoginStart} onLoginError={onLoginError} />
        </div>
        {this.renderSignUpLink()}
      </div>
    );
  }
}

export default SignIn;
