import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Router, Route, browserHistory } from 'react-router';

import AppRedirect from './AppRedirect';
import SignInSignUpSwitcher from './SignInSignUpSwitcher';
import AssociateMfaComponent from './Mfa/AssociateMfa';
import ValidateMfaComponent from './Mfa/ValidateMfa';
import VerifyEmail from './VerifyEmail';

import { Options } from '../types';

interface RoutedOptions {
  route: { options: Options };
}

const Login = ({ route: { options } }: RoutedOptions) => <SignInSignUpSwitcher signin options={options} />;

const Signup = ({ route: { options } }: RoutedOptions) => <SignInSignUpSwitcher options={options} />;
Signup.propTypes = {
  route: PropTypes.object
};

const AssociateMfa = ({
  route: { flashes, mfaBarcodeUri, validateOtpPath, cancelMfaPath }
}: {
  route: Partial<Options>;
}) => (
  <AssociateMfaComponent
    flashes={flashes}
    mfaBarcodeUri={mfaBarcodeUri}
    validateOtpPath={validateOtpPath}
    cancelMfaPath={cancelMfaPath}
  />
);
AssociateMfa.propTypes = {
  route: PropTypes.object
};

const ValidateMfa = ({ route: { flashes, validateOtpPath, cancelMfaPath } }: { route: Partial<Options> }) => (
  <ValidateMfaComponent flashes={flashes} validateOtpPath={validateOtpPath} cancelMfaPath={cancelMfaPath} />
);
ValidateMfa.propTypes = {
  route: PropTypes.object
};

const VerifyEmailRoute = ({ route: { options } }: RoutedOptions) => (
  <VerifyEmail
    loginPath={options.loginPath}
    verifyEmailPath={options.verifyEmailPath}
    companyName={options.companyName}
  />
);

const AppRedirectRoute = () => <AppRedirect redirectUrl={_.get(window.socrata, 'redirectUrl', '')} />;

interface Props {
  config: Options;
}

class Authentication extends Component<Props> {
  render() {
    const { config } = this.props;
    const {
      associateMfaPath,
      flashes,
      loginPath,
      mfaBarcodeUri,
      signupPath,
      validateMfaPath,
      validateOtpPath,
      cancelMfaPath,
      verifyEmailPath,
      companyName
    } = config;

    return (
      <Router history={browserHistory}>
        <Route path="/">
          <Route path={loginPath} component={Login} options={config} />
          <Route path={signupPath} component={Signup} options={config} />
          <Route
            component={AssociateMfa}
            flashes={flashes}
            mfaBarcodeUri={mfaBarcodeUri}
            path={associateMfaPath}
            validateOtpPath={validateOtpPath}
            cancelMfaPath={cancelMfaPath}
          />
          <Route
            component={ValidateMfa}
            path={validateMfaPath}
            flashes={flashes}
            validateOtpPath={validateOtpPath}
            cancelMfaPath={cancelMfaPath}
          />
          <Route path={verifyEmailPath} component={VerifyEmailRoute} options={config} />
          <Route path={'/app_redirect'} component={AppRedirectRoute} />
        </Route>
      </Router>
    );
  }
}

export default Authentication;
