import React, { useState, useEffect } from 'react';

import { Alert } from '../../types';

import './alerts.scss';

const classNameScope = 'frontend--authentication--components--alerts';

const Alerts = ({ alerts }: { alerts: (Alert | null)[] }) => {
  // this will be set to a ref of the first alert in the list
  // this is used to focus on the alert when it renders
  const [firstAlertRef, setFirstAlertRef] = useState<HTMLElement | null>(null);

  // this will focus on the first alert in the list when it's set
  // this is mainly so that screen readers will read this out loud
  useEffect(() => {
    if (firstAlertRef) {
      firstAlertRef.focus();
    }
  }, [firstAlertRef]);

  const renderedMessages = alerts.map((alert, index) => {
    if (!alert) {
      return null;
    }
    const { message, level } = alert;

    return (
      <div
        key={index}
        className={`alert ${level} ${classNameScope}--alert-base`}
        role="alert"
        tabIndex={0}
        ref={(ref) => {
          // only set the ref it it's not already set;
          // this makes it so that we only grab the first alert in the list
          if (ref && !firstAlertRef) {
            setFirstAlertRef(ref);
          }
        }}
        dangerouslySetInnerHTML={{ __html: message }}
      />
    );
  });

  return <div>{renderedMessages}</div>;
};

export default Alerts;
