import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import url from 'url';

import Form from 'common/components/Forms/Form';
import I18n from 'common/i18n';
import EmailVerificationApi from 'common/core/email_verification';
import Button, { SIZES, VARIANTS } from 'common/components/Button';
import Alert, { AlertType } from 'common/components/Alert';

interface State {
  resendButtonActive: boolean;
  resendButtonDisabled: boolean;
  showError: boolean;
}

interface Props {
  companyName: string;
  loginPath: string;
  verifyEmailPath: string;
}

class VerifyEmail extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      resendButtonDisabled: false,
      resendButtonActive: false,
      showError: false
    };
  }

  getUnverifiedEmail = (): string => {
    const parsedUrlParameters = url.parse(location.search, true);

    if (isEmpty(parsedUrlParameters) || parsedUrlParameters.query.unverified_email == null) {
      return '';
    }
    const emailFound = parsedUrlParameters.query.unverified_email as string;
    return emailFound.trim();
  };

  getHasErrorState = (): boolean => {
    const parsedUrlParameters = url.parse(location.search, true);
    if (isEmpty(parsedUrlParameters) || parsedUrlParameters.query.is_invalid == null) {
      return false;
    }
    const isInvalid = parsedUrlParameters.query.is_invalid as string;
    return isInvalid?.toLowerCase() === 'true' ? true : false;
  };

  resendEmail = () => {
    const unverifiedEmail = this.getUnverifiedEmail();
    if (isEmpty(unverifiedEmail)) {
      return;
    }

    this.setState({ resendButtonActive: true, resendButtonDisabled: true, showError: false });
    EmailVerificationApi.resendEmailVerification({ userIdentifier: unverifiedEmail })
      .then(() => {
        this.setState({ resendButtonActive: false });
      })
      .catch(() => {
        this.setState({
          resendButtonActive: false,
          resendButtonDisabled: false,
          showError: true
        });
      });
  };

  renderTitle = () => {
    return !this.getHasErrorState()
      ? I18n.t('account.common.verify_email.title')
      : I18n.t('account.common.verify_email.error_title');
  };

  renderMessage = () => {
    const { companyName } = this.props;

    return !this.getHasErrorState()
      ? I18n.t('account.common.verify_email.message', { company: companyName })
      : I18n.t('account.common.verify_email.error_message');
  };

  render() {
    const { loginPath } = this.props;

    const closeError = () => {
      this.setState({ showError: false });
    };

    const buttonText = this.state.resendButtonDisabled
      ? I18n.t('account.common.verify_email.sent')
      : I18n.t('account.common.verify_email.resend');

    return (
      <div id="email-verification-wrapper">
        <div id="email-verification-errors">
          <Alert show={this.state.showError} type={AlertType.Error}>
            {I18n.t('account.common.verify_email.error')}
            <span onClick={closeError} className="close-button icon-close-2" />
          </Alert>
        </div>
        <div id="email-verification">
          <h1 id="email-verification-title">{this.renderTitle()}</h1>
          <span id="email-verification-text">
            <p>{this.renderMessage()}</p>
            <p>{I18n.t('account.common.verify_email.please_wait')}</p>
          </span>
          <Form>
            <a href={loginPath}>
              <Button type={'button'} size={SIZES.SMALL} variant={VARIANTS.DEFAULT}>
                {I18n.t('account.common.form.back')}
              </Button>
            </a>
            <Button
              type={'button'}
              size={SIZES.SMALL}
              variant={VARIANTS.PRIMARY}
              disabled={this.state.resendButtonDisabled}
              onClick={this.resendEmail}
            >
              {buttonText}
            </Button>
          </Form>
        </div>
      </div>
    );
  }
}

export default VerifyEmail;
