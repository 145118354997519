import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import url from 'url';

import I18n from 'common/i18n';
import Button, { VARIANTS, SIZES } from 'common/components/Button';
import Form from 'common/components/Forms/Form';
import { assign as windowLocationAssign } from 'common/window_location';

import { isValidEmail, findConnection, isSpoofing, createCoreAuthorizeUri } from '../../Util';
import { Alert, Email, Options, SsoConnection } from './../../types';
import { processAuth0Error, Auth0Error } from '../../Auth0Errors';
import EmailInput from './EmailInput';
import PasswordInput from './PasswordInput';

import './sign-in-form.scss';

export const classNameScope = 'frontend--authentication--components--sign-in-form';

export interface State {
  /**
   * Current value in the email field.
   *
   * Email is tracked to detect when an SSO email domain
   * is entered, and selects the proper connection
   */
  email: string | null;

  /** Current value in the password field */
  password: string;

  /**
   * The connection name is found based off of the email
   * either by the email domain or the "forced connections" config option
   */
  connection: SsoConnection | null;

  /** Any errors that happened during login */
  error: Auth0Error | null;

  /**
   * if this is true, and there are no errors,
   * then a spinner is rendered
   */
  loggingIn: boolean;
  spoofing?: boolean;
}

export interface Props {
  options: Options;
  onLoginStart: () => void;
  onLoginError: (level: Alert['level'], i18n: string) => void;
}

class SignInForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      connection: null,
      error: null,
      loggingIn: false
    };
  }

  getEmailPreFilled = () => {
    const parsedUrlParameters = url.parse(location.search, true);

    if (isEmpty(parsedUrlParameters.query) || parsedUrlParameters.query.email == null) {
      return '';
    }
    const emailToSet = parsedUrlParameters.query.email as string;

    return emailToSet.trim();
  };

  /**
   * Sets this.state's email, also looks up a matching connection to
   * login with based on the email domain
   */
  onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;

    if (isSpoofing(email)) {
      this.setState({ email, connection: null, spoofing: true });
    } else if (isValidEmail(email)) {
      this.findConnectionAndSetState(email);
    } else {
      this.setState({ email: null, connection: null, spoofing: false });
    }
  };

  onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: event.target.value });
  };

  onLoginStart = () => {
    this.props.onLoginStart();
    this.setState({ loggingIn: true });
  };

  onLoginError = (error: Auth0Error) => {
    this.setState({ loggingIn: false, password: '' });

    const { level, message } = processAuth0Error(error);
    this.props.onLoginError(level, message);
  };

  onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { options } = this.props;
    const { connection, email, spoofing } = this.state;
    const { ssoConnections, disableSso, platformAdminConnection } = options;

    // blank out error
    this.setLoginErrorMessage(null);

    if (spoofing) {
      // if we're spoofing, just submit the form;
      // spoofing itself is handled by frontend/core
      this.onLoginStart();
      event.currentTarget.submit();
    } else if (connection) {
      // we already have a connection name; just use that
      this.auth0Login(connection);
    } else if (!isEmpty(email) && disableSso === false) {
      // make sure we *really* shouldn't have a connection...
      const foundConnection = findConnection(email!, ssoConnections!, platformAdminConnection);
      if (foundConnection) {
        // if an email was entered and matched a connection, use that connection
        this.auth0Login(foundConnection);
      } else {
        // otherwise do a regular ol login
        this.formLogin(event.currentTarget);
      }
    } else {
      // by default, we just do a login when the fields are blank;
      // frontend will redirect back to the login page with a flash
      // describing what went wrong.
      this.formLogin(event.currentTarget);
    }
  };

  setLoginErrorMessage = (error: Auth0Error | null) => {
    if (!isEmpty(error)) {
      console.error(error);
    }

    this.setState({ error });
  };

  getFormActionUrl = () => {
    const { options } = this.props;
    const { userSessionsPath } = options;

    return userSessionsPath;
  };

  /**
   * Attempts to find a connection for the given email address and,
   * if one is found, sets the state's "connectionName" to be the connection.
   *
   * If no connection is found, then only the state's "email" is updated.
   *
   * Expects the email to have already been validated.
   */
  findConnectionAndSetState = (email: Email) => {
    const { options } = this.props;
    const { ssoConnections, disableSso, platformAdminConnection } = options;

    if (disableSso === false) {
      // if we're using SSO, try and find a connection for the entered email
      const connection = findConnection(email, ssoConnections, platformAdminConnection);
      this.setState({ email, connection, spoofing: false });
    } else {
      // otherwise just update state with the email
      this.setState({ email, connection: null, spoofing: false });
    }
  };

  auth0Login = (connection: SsoConnection) => {
    this.onLoginStart();
    this.auth0LoginViaCore(connection);
  };

  /**
   * Redirect the user to core to initiate the login via Auth0
   */
  auth0LoginViaCore = (connection: SsoConnection) => {
    const { options } = this.props;
    const { baseDomainUri } = options;

    windowLocationAssign(createCoreAuthorizeUri(baseDomainUri, connection));
  };

  formLogin = (formDomNode: HTMLFormElement) => {
    const { email, password } = this.state;

    if (!isValidEmail(email!, true)) {
      this.props.onLoginError('error', I18n.t('core.validation.email'));
    } else if (isEmpty(password)) {
      this.props.onLoginError('error', I18n.t('account.common.form.password_required'));
    } else {
      this.onLoginStart();
      formDomNode.submit();
    }
  };

  shouldRenderSpinner = () => {
    const { error, loggingIn } = this.state;

    return isEmpty(error) && loggingIn === true;
  };

  renderError = () => {
    const { error } = this.state;

    if (!isEmpty(error)) {
      return (
        <div className="signin-form-error alert error">
          <strong>{I18n.t('screens.sign_in.error')}:</strong> {error?.message}
        </div>
      );
    }

    return null;
  };

  render() {
    const { options } = this.props;
    const { connection } = this.state;
    const { disableSignInAutocomplete, forgotPasswordUrl } = options;

    const shouldRenderSpinner = this.shouldRenderSpinner();

    return (
      <Form
        action={this.getFormActionUrl()}
        onSubmit={this.onFormSubmit}
        autoComplete={disableSignInAutocomplete ? 'off' : 'on'}
      >
        {this.renderError()}

        <EmailInput
          defaultValue={this.getEmailPreFilled()}
          onChange={this.onEmailChange as (event: React.ChangeEvent<HTMLInputElement>) => void}
        />
        <PasswordInput
          onChange={this.onPasswordChange as (event: React.ChangeEvent<HTMLInputElement>) => void}
          connection={connection}
        />

        <a href={forgotPasswordUrl} className={`${classNameScope}--reset-password`}>
          {I18n.t('screens.sign_in.forgot_password')}
        </a>

        <Button
          variant={VARIANTS.PRIMARY}
          dark
          size={SIZES.LARGE}
          busy={shouldRenderSpinner}
          className={`${classNameScope}--sign-in-button`}
          type="submit"
        >
          {I18n.t('screens.sign_in.form.sign_in_button')}
        </Button>
      </Form>
    );
  }
}

export default SignInForm;
