import React, { Component } from 'react';

import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import Input from 'common/components/Forms/Input';
import I18n from 'common/i18n';

import { classNameScope } from './SignInForm';

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue: string;
}

class EmailInput extends Component<Props> {
  render() {
    const { onChange, defaultValue } = this.props;

    return (
      <div>
        <div className={`${classNameScope}--icon-container`}>
          <SocrataIcon name={IconName.Email} />
        </div>
        <Input
          tabIndex={0}
          autoFocus
          defaultValue={defaultValue}
          name="user_session[login]"
          aria-label={I18n.t('screens.sign_in.form.email_placeholder')}
          className={`text-input ${classNameScope}--input-email`}
          type="email"
          placeholder={I18n.t('screens.sign_in.form.email_placeholder')}
          spellCheck={false}
          onChange={onChange}
        />
      </div>
    );
  }
}

export default EmailInput;
